<template>
  <div v-if="false" class="paginator-container">
    <div class="prova mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>
        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option
            v-for="show in forPageOption"
            :key="show"
            :value="show"
            class="form-select"
          >
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container pb-3 overflow-auto">
    <table v-if="documents" class="w-full">
      <thead style="position: sticky; top: 0">
        <tr>
          <th class="t-header">
            {{ searchDaily ? 'Documento' : 'Data' }}
          </th>
          <th class="t-header">
            {{ searchDaily ? 'N.' : 'N. Doc' }}
          </th>
          <th v-if="searchDaily" class="t-header">Intestatario</th>
          <th v-if="searchDaily" class="t-header">Metodo Accredito</th>
          <th :colspan="searchDaily ? 1 : 2" class="t-header">Imponibile</th>
          <th :colspan="searchDaily ? 1 : 2" class="t-header">Iva</th>
          <th class="t-header">Ritenuta</th>
          <th :colspan="searchDaily ? 1 : 2" class="t-header">Subtotale</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(doc, docKey) in documents['data']"
          :key="docKey"
          class="h-10 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td
            v-if="searchDaily"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.type }}
          </td>
          <td v-else class="text-center text-sm border-r-2 border-theme-46">
            {{ $FormatDateLocal(doc.date) }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            <template v-if="typeof doc.n_doc == 'object' && doc.n_doc['n'] > 1">
              {{ doc.n_doc['n'] }}
              <span class="text-xs">
                {{
                  doc.n_doc['from']
                    ? 'da ' + doc.n_doc['from'] + ' a ' + doc.n_doc['to']
                    : ''
                }}
              </span>
            </template>
            <template v-else>
              {{
                typeof doc.n_doc == 'object' && doc.n_doc['n'] == 1
                  ? 'n.' + doc.n_doc['from'] ?? doc.n_doc['to']
                  : doc.n_doc
              }}
            </template>
          </td>

          <td
            v-if="searchDaily"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.customer }}
          </td>
          <td
            v-if="searchDaily"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.payment }}
          </td>
          <td
            :colspan="searchDaily ? 1 : 2"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.imponibile }}€
          </td>
          <td
            :colspan="searchDaily ? 1 : 2"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.iva }}€
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.ritenuta }}€
          </td>
          <td
            :colspan="searchDaily ? 1 : 2"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.totale }}€
          </td>
        </tr>
      </tbody>
      <tfoot style="position: sticky; bottom: -15px; background-color: white">
        <tr>
          <td
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            <span v-if="searchDaily"
              >Totale:
              <strong>{{ documents['tot']?.n_docTotale }}</strong></span
            >
          </td>
          <td
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            <span v-if="!searchDaily"
              >Tot: <strong>{{ documents['tot']?.n_docTotale }}</strong></span
            >
          </td>
          <td
            v-if="searchDaily"
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          ></td>
          <td
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          ></td>
          <td
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            Tot. <strong>{{ documents['tot']?.imponibileTotale }}€</strong>
          </td>
          <td
            :colspan="searchDaily ? 1 : 2"
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            Tot. <strong>{{ documents['tot']?.ivaTotale }}€</strong>
          </td>
          <td
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            Tot.
            <strong>{{ documents['tot']?.ritenutaTotale ?? '0.00' }}€</strong>
          </td>
          <td
            :colspan="searchDaily ? 1 : 2"
            class="text-center text-sm border-r-2 border-t-4 border-theme-46 pt-3"
          >
            Tot. <strong>{{ documents['tot']?.prezzoTotale }}€</strong>
          </td>
        </tr>
      </tfoot>
    </table>
    <div v-else class="w-full flex justify-center mt-2">
      <span v-if="loadingSearch && totalRecords == 0">
        <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
        Caricamento in corso...</span
      >
      <span v-else> Nessun Risultato</span>
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import Paginator from '../../components/paginator/Main'

export default defineComponent({
  components: {
    Paginator
  },
  props: {
    documents: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    loadingSearch: Boolean,
    searchDaily: Boolean,
    selectedPatient: Boolean,
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    }
  },
  emits: ['changePagination'],
  setup(props, { emit }) {
    const forPageOption = [10, 15]
    const pagination = ref({
      page: 1,
      forPage: forPageOption[0]
    })

    // variabili filtri
    const filtered_documents = ref(null)
    const active_filters = ref([])

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
        emit('changePagination', value)
      }
    }

    return {
      forPageOption,
      pagination,
      changePagination,
      filtered_documents,
      active_filters
    }
  },
  watch: {
    documents(value) {
      this.filtered_documents = value
      this.active_filters.push('delete')
    }
  },
  methods: {
    // metodo che cambia il numero della pagina
    changeNumPages: function (elem) {
      if (this.pagination.forPage != elem.target.value) {
        this.pagination.forPage = elem.target.value
        this.$emit('changePagination', this.pagination)
      }
    },

    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
