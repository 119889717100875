<template>
  <!-- BEGIN: Documents-->
  <div class="pb-4 my-2">
    <div class="w-full">
      <AttachementComponent :pet_id="petDetails.id" @refreshTable="getDocs" />

      <div class="paginator-container">
        <div class="prova mr-5 my-2 flex justify-between flex-1">
          <div class="my-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>

            <select
              id=""
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option v-for="show in forPageOption" :key="show" :value="show">
                {{ show }}
              </option>
            </select>

            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>

        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="(event) => changePagination(event)"
        />
      </div>

      <!-- BEGIN: Data List -->
      <div class="table-container bg-white scrollbar_custom pb-5">
        <table class="w-full">
          <THeader
            v-for="(item, itemKey) in tableData.config"
            :key="itemKey"
            :name="item.title"
            class="t-header h-10"
          />
          <tbody>
            <tr>
              <td
                v-for="(elem, index) in tableData.input"
                :key="index"
                class="text-center px-1 py-3"
              >
                <select
                  v-if="elem.type === 'select'"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                >
                  <option selected>{{ elem.title }}</option>
                  <option v-for="(item, i) in elem.option" :key="i" value="">
                    {{ item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(doc, i) in docs"
              :key="i"
              class="text-base font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': i % 2 === 0 }"
            >
              <Cell
                :elementi="$FormatDate(doc.last_update)"
                class="text-center text-sm border-r-2 border-theme-46"
              />
              <!-- <Cell
                :elementi="
                  doc.type == 'radiografia' ? 'Radiografia' : 'Documento'
                "
                class="text-center text-sm border-r-2 border-theme-46"
              /> -->
              <Cell
                :elementi="
                  doc.file_name == 'dicom_images'
                    ? doc.file_original_name
                    : doc.type == 'radiografia'
                    ? 'Radiografia'
                    : 'Documento'
                "
                class="text-center text-sm border-r-2 border-theme-46"
              />
              <!--<Cell
                :elementi="doc.file_original_name"
                class="text-center text-sm border-r-2 border-theme-46"
              />-->
              <!-- <Cell
                :elementi="
                  doc.file_name == 'dicom_images'
                    ? doc.file_url
                    : doc.file_original_name ?? doc.description
                "
                class="text-center text-sm border-r-2 border-theme-46"
              /> -->

              <Cell
                :elementi="
                  doc.file_name == 'dicom_images'
                    ? getFileName(doc.file_url)
                    : doc.file_original_name ?? doc.description
                "
                class="text-center text-sm border-r-2 border-theme-46"
              />
              <Cell
                :elementi="
                  doc.staff_user
                    ? doc.staff_user.first_name && doc.staff_user.last_name
                      ? doc.staff_user.last_name +
                        ' ' +
                        doc.staff_user.first_name
                      : doc.staff_user.username
                    : ''
                "
                class="text-center text-sm border-r-2 border-theme-46"
              />
              <td class="flex text-xl text-center lg:block">
                <a
                  v-if="doc.file_name === 'dicom_images'"
                  class="cursor-pointer"
                  @click="handleDownloadFile(doc)"
                >
                  <i class="p-3 fas fa-download"></i>
                </a>

                <a
                  v-if="doc.file_name !== 'dicom_images'"
                  :href="doc.file_url"
                  target="blank"
                  class="cursor-pointer"
                >
                  <i class="p-3 fas fa-file-pdf"></i>
                </a>

                <a class="cursor-pointer" @click="handleDeleteFile(doc)">
                  <i class="p-3 fas fa-times" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- END: Documents-->
</template>

<script>
import { defineComponent } from 'vue'
import THeader from '../../components/cvit-table/THeader.vue'
import Paginator from '../paginator/Main'
import Cell from '../../components/cvit-table/Cell.vue'
import AttachementComponent from '../details-customer/AttachementComponent.vue'
import axios from '../../gateway/backend-api'

const forPageOption = [5, 10, 50]
export default defineComponent({
  name: 'PetDocuments',
  components: {
    Paginator,
    THeader,
    Cell,
    AttachementComponent
  },
  props: {
    doc: {
      type: Object,
      required: true
    },
    petDetails: Object
  },
  emits: ['setTotalRecords'],
  data() {
    return {
      docs: null,
      totalRecords: '',
      forPageOption,
      active_filters: {},
      filterActive: false,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      tableData: {
        config: [
          {
            key: 'last_update',
            title: 'Data'
          },
          {
            key: 'type',
            title: 'Tipologia'
          },
          /*{
            key: 'file_original_name',
            title: 'Documento'
          },*/
          {
            key: 'description',
            title: 'Descrizione',
            type: 'Text'
          },
          {
            key: 'staff_user',
            title: 'Medico'
          },
          {
            key: 'quick-actions',
            title: 'Azioni Rapide'
          }
        ],
        input: [
          {
            key: 'last_update',
            title: 'Data',
            type: 'date'
          },
          {
            key: 'type',
            title: 'Tipologia',
            type: 'text'
          },
          /*{
            key: 'file_original_name',
            title: 'Documento',
            type: 'text'
          },*/
          {
            key: 'description',
            title: 'Descrizione',
            type: 'text'
          },
          {
            key: 'staff_user',
            title: 'Medico',
            type: 'text'
          }
        ]
      },
      disabled: true,
      selected: []
    }
  },
  mounted() {
    this.getDocs()
  },
  methods: {
    getFileName(url) {
      return url.split('/').pop().replace('.folder', '')
    },
    handleSavePdfRev(elem) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      const url = window.URL.createObjectURL(new Blob([elem]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Documento_' + elem.number + '.pdf')
      document.body.appendChild(link)
      this.$store.dispatch('commonComponents/openPdfModal', {
        title: 'Titolo esempio',
        link: elem,
        background: false,
        print: () => {
          console.log('PRINT')
        },
        download: () => {
          link.click()
        }
      })
      this.$store.dispatch('commonComponents/hideLoadingModalAction')
    },
    handleCompareButton: function () {
      if (this.selected.length > 1 && this.selected.length < 3) {
        this.disabled = false
      } else {
        this.disabled = true
      }
      return this.disabled
    },
    filterHandler(elem) {
      if (elem.type == 'date') {
        this.active_filters[elem.key] = elem.value
        // this.active_filters[elem.key] = this.$FormatEpoch(
        //   moment(elem.value).format('DD/MM/YYYY')
        // )
      } else {
        this.active_filters[elem.key] = elem.value
      }

      this.checkIfFilterActive()

      if (this.filterActive) this.getDocs()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    getDocs() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/attachments', {
          pet_id: this.petDetails.id,
          // type: 'documents',
          active_filters: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.docs = res.data.data
          this.totalRecords = res.data.total
          this.$emit('setTotalRecords', this.totalRecords)
          console.log('pet doc this.emit', this.totalRecords)
        })
    },
    changePagination: function (value) {
      if (this.pagination.page != value) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getDocs()
      }
    },
    changeNumPages: function (elem) {
      if (this.pagination.forPage != elem.target.value) {
        this.pagination.forPage = elem.target.value
        this.getDocs()
      }
    },
    handleDeleteFile: function (doc) {
      axios
        .delete(
          '/' +
            localStorage.getItem('clinic') +
            '/attachments/' +
            doc.id +
            '/delete-file'
        )
        .then(() => {
          this.getDocs()
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'File rimosso con successo!'
          })
        })
    },
    handleDownloadFile: function (doc) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vetslab-exams/download-dicom-exam',
          { docId: doc.id },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const data = response.data
          if (data.fileContent) {
            const byteCharacters = atob(data.fileContent)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], { type: 'application/dicom' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = data.fileName
            link.click()
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'File scaricato con successo!'
            })
          } else {
            console.error(data.error)
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          }
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore'
          })
        })
    }
  }
})
</script>
